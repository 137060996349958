<template>
  <div id="box">
    <div class="left"></div>
    <div class="right">
      <div class="box">
        <img src="../../assets/login/Capgemini_Logo_Color.png" alt="" />
        <!-- 区分当前环境 -->
        <p v-if="currentEnv.flag" id="show-env">
          {{ currentEnv.name }}
        </p>
        <a-form
          class="form"
          ref="formRef"
          :model="formState"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          layout="vertical"
          :hideRequiredMark="true"
        >
          <a-form-item label="邮箱" name="email">
            <a-input
              placeholder="请输入邮箱"
              v-model:value="formState.email"
              allowClear
            >
              <template #suffix>
                <span class="suffixText">@capgemini.com</span>
              </template>
            </a-input>
          </a-form-item>
          <a-form-item label="图形验证码" name="imgCode" class="imgCode">
            <a-input
              autoComplete="off"
              placeholder="请输入图形验证码"
              v-model:value="formState.imgCode"
              @pressEnter="onSubmit"
            />
            <img :src="imgCodeUrl" @click="reloadImgCode" />
          </a-form-item>
          <a-form-item label="验证码" name="code" class="code">
            <a-input
              autoComplete="off"
              placeholder="请输入验证码"
              v-model:value="formState.code"
              @pressEnter="onSubmit"
            />
            <div class="btn">
              <div
                v-if="isShow === true"
                class="getyzm"
                @click="getVerificationCode"
              >
                获取验证码
              </div>
              <div v-else class="resetyzm">
                重新发送<span>{{ num }}s</span>
              </div>
            </div>
          </a-form-item>
          <div v-if="currentEnv.flag" style="position: absolute;" @click="checked">
            <a-radio v-model:checked="noVerificationCode">无需验证码登录</a-radio>
          </div>
          <a-form-item>
            <a-button type="primary" :loading="loading" block @click="onSubmit"
              >登录
            </a-button>
          </a-form-item>
        </a-form>
        <div style="width: 100%;position: absolute;bottom: 1px;">
          <a-divider style="border-color: #CCCCCC; margin:0 0 2px 0;" dashed />
          <div class="info">
            <span><img src="../../assets/login/relation.png" alt="" class="iconInfo"/></span>
            <span class="infos">{{getAdmin.fullName}}</span><span class="xicon">|</span><a :href='cnEmail' class="infos" style="text-decoration: underline;">{{getAdmin.email}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, watch, toRefs, onMounted, computed} from "vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { fillZero } from "@/utils/common.js";

import { currentEnv } from "@/utils/showEnv.js";
export default defineComponent({
  components: {},
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const router = useRouter();
    const formRef = ref();
    const state = reactive({
      isShow: true,
      loading: false,
      num: 180,
      timer: null,
      formState: {
        email: "",
        code: "",
        imgCode: "",
        imgCodeKey: "",
        uuid: "",
      },
    });
    const rules = {
      email: [
        // {
        //   required: true,
        //   trigger: "blur",
        //   message: "请输入邮箱",
        // },
        {
          pattern: /^([0-9a-zA-Z.-])+$/,
          message: "邮箱格式错误",
          trigger: "change",
        },
      ],
      // code: [
      //   {
      //     required: true,
      //     trigger: "blur",
      //     message: "请输入验证码",
      //   },
      // ],
      // imgCode: [
      //   {
      //     required: true,
      //     trigger: "blur",
      //     message: "请输入图形验证码",
      //   },
      // ],
    };
    watch(
      () => state.num,
      (val) => {
        if (val <= 0) {
          clearInterval(state.timer);
          state.num = 180;
          state.isShow = true;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const validateForm = (isValidateCode = true) => {
      if (state.formState.email.length == 0) {
        message.error("请输入邮箱");
        return false;
      }
      if (!state.formState.imgCode) {
        message.error("请输入图形验证码");
        return false;
      }
      if (isValidateCode && !state.formState.code) {
        message.error("请输入验证码");
        return false;
      }
      return true;
    };
    const getVerificationCode = () => {
      if (!validateForm(false)) {
        return;
      }
      formRef.value.validate().then(() => {
        const params = {
          email: state.formState.email + "@capgemini.com",
          picCode: state.formState.imgCode,
          codeKey: state.formState.imgCodeKey,
        };
        $api
          .getVerificationCodeEmail(params)
          .then((res) => {
            state.formState.uuid = res.data.uuid;
            sessionStorage.setItem("uuid", res.data.uuid);
            state.isShow = false;
            state.timer = setInterval(() => {
              state.num = fillZero(state.num - 1);
            }, 1000);
          })
          .catch(() => {
            reloadImgCode();
          });
      });
    };
    const noVerificationCode = ref(false)
    const checked = () => {
      noVerificationCode.value = !noVerificationCode.value
    }
    const onSubmit = () => {
      if(noVerificationCode.value === true){
        if (state.formState.email.length === 0) {
          message.error("请输入邮箱");
          return false;
        } else {
          const params = {
            email: state.formState.email + "@capgemini.com",
            code: state.formState.code,
            picCode: state.formState.imgCode,
            codeKey: state.formState.imgCodeKey,
            uuid: state.formState.uuid,
          };
          $api
              .noLoginByEmail(params)
              .then((res) => {
                if (res.code == 200) {
                  res.userinfo.roles = [res.roleId];
                  state.loading = false;
                  store.commit("SET_TOKEN", res.token.token);
                  store.commit("SET_USERINFO", res.userinfo);
                  store.commit("SET_DEFAULT_ROLE", res.roleName);
                  localStorage.setItem("accessToken", res.token.token)
                  localStorage.setItem("userInfo", JSON.stringify(res.userinfo))
                  localStorage.setItem("switchRoleName", res.roleName)
                  store.dispatch("generateMenus");
                  const originPath = localStorage.getItem("originPath")
                  if (originPath) {
                    router.push(originPath);
                  } else if (
                      (res.roles.length === 2 &&
                          res.roles.indexOf("4") !== -1 &&
                          res.roles.indexOf("5") !== -1) ||
                      (res.roles.length === 1 &&
                          (res.roles.indexOf("4") !== -1 ||
                              res.roles.indexOf("5") !== -1))
                  ) {
                    router.push({path: "/userInfo"});
                  } else {
                    router.push("/home");
                  }
                } else {
                  reloadImgCode();
                }
              })
              .catch(() => {
                state.loading = false;
                reloadImgCode();
              });
        }
      } else {
        if (!validateForm()) {
          return;
        }
        formRef.value.validate().then(() => {
          state.loading = true;
          state.formState.uuid =
              state.formState.uuid !== ""
                  ? state.formState.uuid
                  : sessionStorage.getItem("uuid");
          const params = {
            email: state.formState.email + "@capgemini.com",
            code: state.formState.code,
            picCode: state.formState.imgCode,
            codeKey: state.formState.imgCodeKey,
            uuid: state.formState.uuid,
          };
          $api
              .loginByEmail(params)
              .then((res) => {
                if (res.code == 200) {
                  res.userinfo.roles = [res.roleId];
                  state.loading = false;
                  store.commit("SET_TOKEN", res.token.token);
                  store.commit("SET_USERINFO", res.userinfo);
                  store.commit("SET_DEFAULT_ROLE", res.roleName);
                  localStorage.setItem("accessToken", res.token.token)
                  localStorage.setItem("userInfo", JSON.stringify(res.userinfo))
                  localStorage.setItem("switchRoleName", res.roleName)
                  store.dispatch("generateMenus");
                  const originPath = localStorage.getItem("originPath")
                  if (originPath) {
                    router.push(originPath);
                  } else if (
                      (res.roles.length === 2 &&
                          res.roles.indexOf("4") !== -1 &&
                          res.roles.indexOf("5") !== -1) ||
                      (res.roles.length === 1 &&
                          (res.roles.indexOf("4") !== -1 ||
                              res.roles.indexOf("5") !== -1))
                  ) {
                    router.push({path: "/userInfo"});
                  } else {
                    router.push("/home");
                  }
                } else {
                  reloadImgCode();
                }
              })
              .catch(() => {
                state.loading = false;
                reloadImgCode();
              });
        });
      }
    };
    //获取admin联系方式
    const getAdmin = ref({})
    const cnEmail = computed(() => 'mailto:' + getAdmin.value.email)
    const getRelation = () => {
      $api.getAdminInformation().then((res) => {
        getAdmin.value = res.data[0]
        store.commit("SET_ADMIN", res.data);
        localStorage.setItem("adminRelation", JSON.stringify(getAdmin.value))
      });
    };
    onMounted(() => {
      getRelation();
    });
    const imgCodeUrl = ref("");
    const reloadImgCode = () => {
      $api.getImgCode().then((res) => {
        state.formState.imgCodeKey = res.data.codeKey;
        imgCodeUrl.value = res.data.code;
      });
    };
    reloadImgCode();

    return {
      ...toRefs(state),
      formRef,
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      rules,
      onSubmit,
      getVerificationCode,
      imgCodeUrl,
      reloadImgCode,
      currentEnv,
      getRelation,
      getAdmin,
      cnEmail,
      noVerificationCode,
      checked,
    };
  },
});
</script>
<style lang="less" scoped>
#box {
  width: 100vw;
  height: 100vh;
  display: flex;
  #show-env {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: red;
    font-weight: bolder;
    position: absolute;
    top: 100px;
    left: 0;
  }
  .left {
    width: 100%;
    background: url(../../assets/login/login.png) no-repeat;
    background-size: cover;
  }

  .suffixText {
    // color: #666;
    font-size: 17px;
  }

  .right {
    .box {
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(-50%, -50%);
      width: 462px;
      height: 625px;
      border-radius: 20px;
      box-sizing: border-box;
      padding-top: 49px;
      background: white;

      img {
        width: 200px;
        height: 50px;
        margin-left: 125px;
        margin-bottom: 60px;
      }

      .form {
        width: 347px;
        position: relative;
        margin: 0 auto;

        :deep(.ant-input) {
          height: 50px;
        }

        :deep(.ant-btn) {
          height: 50px;
          margin-top: 30px;
        }

        .code {
          position: relative;
        }

        .btn {
          cursor: pointer;
          position: absolute;
          top: 15px;
          line-height: 20px;
          right: 15px;

          .resetyzm {
            color: rgb(0, 47, 255);
          }

          .getyzm {
            color: rgb(0, 47, 255);
          }
        }
      }

      .imgCode {
        ::v-deep .ant-form-item-control-input-content {
          display: flex;
        }

        .ant-input {
          flex: 1;
          width: auto;
        }

        img {
          margin: 0;
          cursor: pointer;
        }
      }
      .info {
        display: flex;
        justify-content: center;
        .iconInfo {
          color: #3182CE;
          width: 12px;
          height: 12px;
          margin-left: 0;
          margin-bottom: 0;
        }
        .infos {
          font-size:12px;
          color:#3182CE;
          margin: 0 8px 0 8px;
          display: flex;
          align-items: center;
        }
        .xicon {
          font-weight: 700;
          color: #3182CE;
          font-size:12px;
        }
      }
    }
  }
}
</style>
